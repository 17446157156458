import {observer} from 'mobx-react-lite';
import type {BaseComponentProps} from '@/components/types';
import type {FC, ImgHTMLAttributes} from 'react';

type Props = BaseComponentProps &
  ImgHTMLAttributes<HTMLImageElement> & {
    src: ImgHTMLAttributes<HTMLImageElement>['src'];
  };

const LivanImage: FC<Props> = observer(function LivanImage(props) {
  const {className, alt, ...imageProps} = props;

  return (
    <img
      alt={alt}
      className={className}
      {...imageProps}
    />
  );
});

export default LivanImage;
